import React, { useState } from 'react'
import Navigation from './Navigation'

export default function Orders() {
  const [navPop,setNavPop] = useState(false)

  return (
    <div>
        <Navigation navigationPop={navPop}/>
    </div>
  )
}
