import React, { Fragment, useState,useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom'
import { addDoc, collection, doc, setDoc,query,where,onSnapshot, serverTimestamp } from 'firebase/firestore';
import { auth, fireStore } from '../firebase';
import {createUserWithEmailAndPassword, sendEmailVerification} from "firebase/auth"
import Navigation from '../views/Navigation';

export default function Register() {
    const [navPop,setNavPop] = useState(false)
    const [password,setpassword] = useState()
    const [email,setemail] = useState('')
    const [name,setName] = useState()
    const [nameError,setNameError] = useState()
    const [duplicate,setduplicate] = useState(false)
    const [isLoading,setIsLoading] = useState(false)
    const [matchedError,setmatchedError] = useState('')
    const [matched,setmatched] = useState(false)
    const [passwordError,setpasswordError] = useState("")
    const [emailError,setemailError] = useState()
    const [confirmPassword,setconfirmPassword] = useState("")
    const [confirmPasswordError,setconfirmPasswordError] = useState("")
  const history = useHistory()


    useEffect(() => {
        
      (async()=>{
    
        const q = query(
          collection(fireStore, "TotalUsers"),
          where("email", "==", email)
        );
      if(email || email){
        onSnapshot(q,snapshot=>{
          snapshot.forEach((doc) => {
            
            if(doc.exists ){
              setduplicate(true)
            }
    
            else {
              setduplicate(false)
            }
           
     })
    
        });
      }
     
      })
     ()
    }, [email,email]);

    const signUp = (e)=>{
      e.preventDefault()
      // if(matched == false){
      //   setmatchedError('error')
      // }
        if(name.length == 0){

          setNameError('error')
          window.scrollTo(0, 0);
    
         }
         else{

              if(email.length == 0 || duplicate == true){

                setemailError('error')
              }
              else {

                if(password.length == 0){

                  setpasswordError('error')
                }
                else{
                  if( confirmPassword.length == 0  || password != confirmPassword){

                    setconfirmPasswordError('error')
                  }
                  else{
                    // if(matched == false){
                      
                    //   setmatchedError('error')
                    // }
                    {
                      setIsLoading(true)
                    
                                    createUserWithEmailAndPassword(auth,email,password).then(
                                      credential=>{
                                        setDoc(doc(fireStore, "TotalUsers",credential.user.uid),
                                        {
                                          fullName:name,
                                          email:email,
                                          password:password,
                                              photo:'',
                    
                                          uid:credential.user.uid,
                                          createdTime:serverTimestamp(),
                                    
                                    
                                    
                                    
                                      }
                                        )
                                      }
                                    ).then
                history.push("/collection");

                                  }
                  }
                 
                }
              }
            }
            
    
       
     
        }
    function validateEmail(email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    }
    const nameSet = (e)=>{
      
      setName(e.target.value)
      if(e.target.value.length == 0){
        setNameError("error")
      }
      else {
        setNameError("")

      }

    }

    const emailSet = (e)=>{
      setduplicate(false)
      setemail(e.target.value)
      if(e.target.value.length == 0){
        setemailError("error")
      }
      else {
        setemailError("")
        if (validateEmail(e.target.value)) {
          setemailError("")
  
          } else {
          setemailError("error")
  
          }

      }

    }

    const passwordSet = (e)=>{

      setpassword(e.target.value)
      if(e.target.value.length <= 7){
        setpasswordError("error")
      }
      else {
        setpasswordError("")

      }
      
   

    }
    const confirmPasswordSet = (e)=>{
      
      setconfirmPassword(e.target.value)
      if(e.target.value != password){
        setconfirmPasswordError("error")
      }
      else {
        setconfirmPasswordError("")

      }

    }




    function navPopHandler(){
        if(navPop === true){
          setNavPop(false)
        }
        else{
          setNavPop(true)
        }
      } 
  return (
    
    <div className=''>
          <Helmet>
          <title>Register - Ephratawi</title>

<meta property="og:title" content="Ephratawi Design" />
      </Helmet>
<Navigation navigationPop={navPop} component={'Register'}/>
      
 
    <div className="shadow-md rounded-md border mx-auto my-20 login_section" style={{backgroundColor:'white',padding:'20px'}}>
      <div className="" >
        <div className="contact-form5-section-title thq-flex-column" >
          <span className="thq-body-small">
               <span 
                      style={{fontSize:'30px',marginTop:'20px',marginBottom:'40px',textAlign:'center',display:'flex',justifyContent:'center'}}       className="nds-text _1MF91zHG css-7vq5hu e1yhcai00 appearance-display2 text-white weight-medium uppercase"
                      >Create an account</span>
          </span>
         

          
        </div>
        

<form class="max-w-md mx-auto" style={{marginTop:'20px'}}>
  
<div class="relative z-0 w-full mb-5 group">
      <input onChange={e=>nameSet(e)}type="text" autocomplete="off"name="floating_name" id="floating_name" style={{borderBottom:'1px solid black',borderRadius:'0',marginBottom:'20px'}}class="input-signUp block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent  border-gray-300 appearance-none   focus:outline-none focus:ring-0 focus:border-green-600 peer" placeholder=" " required />
      <label for="floating_name" class=" peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-7 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-green-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Full Name</label>
  </div>
  <div class="relative z-0 w-full mb-5 group">
      <input onChange={e=>emailSet(e)}type="email" autocomplete="off"name="floating_email" id="floating_email" style={{borderBottom:'1px solid black',borderRadius:'0',marginBottom:'20px'}}class="input-signUp block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent  border-gray-300 appearance-none   focus:outline-none focus:ring-0 focus:border-green-600 peer" placeholder=" " required />
      <label for="floating_email" class=" peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-7 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-green-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Email address</label>
  </div>



    <div onChange={e=>passwordSet(e)}class="relative z-0 w-full mb-5 group">
        <input type="password"  autocomplete="off"name="floating_password" id="floating_password" style={{borderBottom:'1px solid black',borderRadius:'0',marginBottom:'20px'}}class="input-signUp block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent  border-gray-300 appearance-none   focus:outline-none focus:ring-0 focus:border-green-600 peer" placeholder=" " required />
        <label for="floating_password" class="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-7  scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-green-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Password </label>
    </div>
    <div onChange={e=>confirmPasswordSet(e)}class="relative z-0 w-full mb-5 group">
        <input type="password"  autocomplete="off"name="floating_password" id="floating_password" style={{borderBottom:'1px solid black',borderRadius:'0',marginBottom:'20px'}}class="input-signUp block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent  border-gray-300 appearance-none   focus:outline-none focus:ring-0 focus:border-green-600 peer" placeholder=" " required />
        <label for="floating_password" class="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-7  scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-green-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Confirm Password </label>
    </div>
   
              <button onClick={signUp} className="button-secondary button bg-transparent">Register</button>

             
</form>

      </div>
    </div>
    </div>
  )
}
