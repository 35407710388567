import React, { Fragment, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom/cjs/react-router-dom'
import './quote.css'
import Navigation from '../views/Navigation.js'
import { onAuthStateChanged } from 'firebase/auth'
import { auth, fireStore } from '../firebase.js'
import { doc, onSnapshot,collection,query,where,orderBy } from 'firebase/firestore'

export default function About() {
    const [navPop,setNavPop] = useState(false)
    const [user,setuser] = useState()
    const [clothes, setclothes] = useState([]);
    const [currentIndexes, setCurrentIndexes] = useState({});
    const [isLoading, setisLoading] = useState(true);

    useEffect(() => {
      const fetchclothes = async () => {
        const document = collection(fireStore, 'clothes');
        const q = query(document, orderBy('postedTime'));
        const unsubscribe = onSnapshot(q, (snapshot) => {
          let temp = [];
          snapshot.docs.forEach((doc) => {
            temp.push({ ...doc.data(), id: doc.id });
          });
          setclothes(temp);
          console.log(clothes)
          setisLoading(false);
        }, (error) => {
          console.error('Error fetching clothes:', error);
          setisLoading(false);
        });
  
        // Clean up the listener when the component unmounts
        return unsubscribe;
      };
  
      fetchclothes();
    }, []);

    useEffect(() => {
      // Initialize currentIndexes state with the first index for each cloth
      const initialIndexes = clothes.reduce((acc, cloth) => {
        acc[cloth.id] = 0;
        return acc;
      }, {});
      setCurrentIndexes(initialIndexes);
    }, [clothes]);
    const handlePrevious = (clothPhotos, id) => {
      setCurrentIndexes((prevIndexes) => ({
        ...prevIndexes,
        [id]: (prevIndexes[id] === 0 ? clothPhotos.length - 1 : prevIndexes[id] - 1),
      }));
    };
    const handleNext = (clothPhotos, id) => {
      setCurrentIndexes((prevIndexes) => ({
        ...prevIndexes,
        [id]: (prevIndexes[id] === clothPhotos.length - 1 ? 0 : prevIndexes[id] + 1),
      }));
    };
    function ClothGallery({ cloth, currentIndex, handlePrevious, handleNext }) {
      return (
        <div className=''>
           <div className='w-full'>
           <div className="relative w-full">
           {cloth.clothPhotos.length >= 2 && (
                   <button
                   type="button"
                   className="z-100 absolute top-0 start-0 z-30 flex items-center justify-center h-full px-2 cursor-pointer group focus:outline-none"
                   data-carousel-prev
                 >
                 <span onClick={() => handlePrevious(cloth.clothPhotos)} className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 cursor-pointer hover:bg-white/50  focus:ring-4 focus:ring-white  group-focus:outline-none">
    
                     <svg
                       
                       className="w-4 h-4 text-gray-800 dark:text-gray-800 rtl:rotate-180"
                       aria-hidden="true"
                       xmlns="http://www.w3.org/2000/svg"
                       fill="none"
                       viewBox="0 0 6 10"
                     >
                       <path
                         stroke="currentColor"
                         stroke-linecap="round"
                         stroke-linejoin="round"
                         stroke-width="2"
                         d="M5 1 1 5l4 4"
                       />
                     </svg>
                     <span className="sr-only">Previous</span>
                   </span>
                 </button>
             
              )}
    
              {cloth.clothPhotos.length >= 2 && (
                 <div
                 type="div"
                 className="absolute top-0 end-0 z-30 flex items-center justify-center h-full px-2  group focus:outline-none"
                 data-carousel-next
               >
                 <span onClick={() => handleNext(cloth.clothPhotos)} className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 cursor-pointer hover:bg-white/50  focus:ring-4 focus:ring-white  group-focus:outline-none">
                   <svg
                     
                     className="w-4 h-4 text-gray-800 dark:text-gray-800 rtl:rotate-180"
                     aria-hidden="true"
                     xmlns="http://www.w3.org/2000/svg"
                     fill="none"
                     viewBox="0 0 6 10"
                   >
                     <path
                       stroke="currentColor"
                       stroke-linecap="round"
                       stroke-linejoin="round"
                       stroke-width="2"
                       d="m1 9 4-4-4-4"
                     />
                   </svg>
                   <span className="sr-only">Next</span>
                 </span>
               </div>
    
              )}
              <Link to={`/cloth/${cloth.id}`}>
             {cloth && cloth.clothPhotos.map((photo, index) => (
            <div className={`productCard-card productCardroot-class-name1  ${index === currentIndex ? 'flex' : 'hidden'}`} style={{backgroundImage:`url(${photo})`,width:'100%'}}>
        <div className="productCard-info card-info">
          <span className="productCard-text1">{cloth.clothTitle} </span>
          <span className="productCard-text2">{cloth.clothPrice} ETB</span>
        </div>
      </div>
                  ))}
  </Link>
            </div>
          
          </div>
         
        </div>
        
      );
    }

    useEffect(() => {

      const unsubscribe = onAuthStateChanged(auth, (user) => {
        if (user) {
      
          const uid = user.uid;


          onSnapshot(doc(fireStore,'TotalUsers',uid),snapshot=>{
              setuser([{...snapshot.data(),id:snapshot.id}])
              

          })
    
    
    
    
              
    
                
            
    
            
        
    
               
    
               
               
    
                  
        
    
          
    
         
          
          // ...
        } else {
          console.log("logged out")
          
        }
      });
    
      
        
    
      return () => {
        unsubscribe();
      };
    }, []);

    function navPopHandler(){
      if(navPop === true){
        setNavPop(false)
      }
      else{
        setNavPop(true)
      }
    } 
  return (
    <div>   <Helmet>
    <title>Ephratawi Collection</title>
    <meta property="og:title" content="Ephratawi Collection" />
  </Helmet>
  <Navigation navigationPop={navPop} component={'Collection'}/>

          <div className=" mt-20">
  
      
          <div className=" home-max-width6 max-width">
          <span className="home-text137 beforeHeading capitalize">Shop Now</span>
          <h1 className="home-text138">
            <span>Our Featured
            </span>
            <br></br>
            <span className='home-text120 capitalize'>Items</span>
          </h1>
         </div> 
      <div className=" clothGrid ">
            {clothes.map((cloth) => (
              <div key={cloth.id}>
                <ClothGallery
                  cloth={cloth}
                  currentIndex={currentIndexes[cloth.id] || 0}
                  handlePrevious={(clothPhotos) => handlePrevious(clothPhotos, cloth.id)}
                  handleNext={(clothPhotos) => handleNext(clothPhotos, cloth.id)}
                />
              </div>
            ))}
          </div>
      
    
    </div>



          
          </div>
  )
}
