import React, { Fragment, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom/cjs/react-router-dom'
import Hero8 from '../views/Hero8.js'
import Stats2 from '../views/Stats2.js'
import Logos1 from '../views/logos.js'
import Features1 from '../views/features1.js'
import Team1 from '../views/Team1.js'
import './quote.css'
import Navigation from '../views/Navigation.js'
import Footer from '../views/Footer.js'

export default function About() {
    const [navPop,setNavPop] = useState(false)
    function navPopHandler(){
      if(navPop === true){
        setNavPop(false)
      }
      else{
        setNavPop(true)
      }
    } 
  return (
    <div>   <Helmet>
    <title>About Us: Ephratawi Design</title>
    <meta property="og:title" content="Ephratawi Design" />
  </Helmet>
<Navigation navigationPop={navPop} component='About'/>
          <div className="about-container1">
  
      <Hero8
        content1={
          <Fragment>
            <span className="about-text115">
              Take control of your finances with our innovative banking system
            </span>
          </Fragment>
        }
        action2={
          <Fragment>
            <span className="about-text116">Learn More</span>
          </Fragment>
        }
        heading1={
          <Fragment>
            <h1 
                   style={{fontSize:'55px',marginBottom:'20px'}}       className="nds-text _1MF91zHG css-7vq5hu e1yhcai00 appearance-display2 text-white weight-medium uppercase"

            >
              <span>
              Welcome to
             
              </span>
              <span className="text-[#0c3a16]"> Rimmon</span>
            </h1>
          </Fragment>
        }
        action1={
          <Fragment>
            <span className="about-text118">Sign Up Now</span>
          </Fragment>
        }
      ></Hero8>
      <Stats2
        stat1={
          <Fragment>
            <span className="about-text119">Average Savings Rate</span>
          </Fragment>
        }
        content1={
          <Fragment>
            <span className="about-text120">Customer Feedback</span>
          </Fragment>
        }
        content2={
          <Fragment>
            <span className="about-text121">
              Business improvements emphasized with numbers to increase
              creadibility
            </span>
          </Fragment>
        }
        stat3={
          <Fragment>
            <span className="about-text122">Payment Processing Speed</span>
          </Fragment>
        }
        stat4={
          <Fragment>
            <span className="about-text123">Financial Products</span>
          </Fragment>
        }
        stat2Description={
          <Fragment>
            <span className="about-text124">
              Earn up to 5% cashback on select purchases with UpStart.
            </span>
          </Fragment>
        }
        heading1={
          <Fragment>
            <span className="about-text125">Key Stats</span>
          </Fragment>
        }
        stat4Description={
          <Fragment>
            <span className="about-text126">
              Access a wide range of financial products tailored to your needs.
            </span>
          </Fragment>
        }
        stat2={
          <Fragment>
            <span className="about-text127">Cashback Rewards</span>
          </Fragment>
        }
        stat3Description={
          <Fragment>
            <span className="about-text128">
              Transactions are processed 30% faster than the industry average.
            </span>
          </Fragment>
        }
        stat1Description={
          <Fragment>
            <span className="about-text129">
              UpStart users save an average of 15% more compared to traditional
              banks.
            </span>
          </Fragment>
        }
      ></Stats2>
      <Logos1
        heading1={
          <Fragment>
            <span className="about-text130">
              Trusted by the world&apos;s best companies social proof to build
              credibility
            </span>
          </Fragment>
        }
      ></Logos1>
      <Features1
        mainAction={
          <Fragment>
            <span className="about-text131">Faster Payment Processing</span>
          </Fragment>
        }
        feature2Title={
          <Fragment>
            <span className="about-text132">Savings Plans</span>
          </Fragment>
        }
        feature1Title={
          <Fragment>
            <span className="about-text133">Cashback Rewards</span>
          </Fragment>
        }
        feature1Description={
          <Fragment>
            <span className="about-text134">
              Earn cashback on your purchases and maximize your savings.
            </span>
          </Fragment>
        }
        slogan={
          <Fragment>
            <span className="about-text135">
              Efficiently manage your finances with UpStart
            </span>
          </Fragment>
        }
        feature3Title={
          <Fragment>
            <span className="about-text136">Global Operations</span>
          </Fragment>
        }
        feature3Description={
          <Fragment>
            <span className="about-text137">
              Access UpStart&apos;s financial services and benefits worldwide.
            </span>
          </Fragment>
        }
        sectionDescription={
          <Fragment>
            <span className="about-text138">
              Explore the key features that make UpStart the ultimate finance
              system for saving and managing your money.
            </span>
          </Fragment>
        }
        secondaryAction={
          <Fragment>
            <span className="about-text139">User-Friendly Mobile App</span>
          </Fragment>
        }
        feature2Description={
          <Fragment>
            <span className="about-text140">
              Choose from a variety of savings plans tailored to your financial
              goals.
            </span>
          </Fragment>
        }
        sectionTitle={
          <Fragment>
            <span className="about-text141">Features</span>
          </Fragment>
        }
      ></Features1>
      <Team1
        member5Content={
          <Fragment>
            <span className="about-text142">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse varius enim in eros elementum tristique.
            </span>
          </Fragment>
        }
        member2Content={
          <Fragment>
            <span className="about-text143">
              Jane is a tech enthusiast who drives our product development with
              cutting-edge solutions.
            </span>
          </Fragment>
        }
        member8Content={
          <Fragment>
            <span className="about-text144">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse varius enim in eros elementum tristique.
            </span>
          </Fragment>
        }
        heading1={
          <Fragment>
            <span className="about-text145">Meet Our Team</span>
          </Fragment>
        }
        member3Job={
          <Fragment>
            <span className="about-text146">CFO</span>
          </Fragment>
        }
        member4Content={
          <Fragment>
            <span className="about-text147">
              Sarah oversees our operations to guarantee efficiency and seamless
              customer experiences.
            </span>
          </Fragment>
        }
        member2Job={
          <Fragment>
            <span className="about-text148">CTO</span>
          </Fragment>
        }
        content2={
          <Fragment>
            <span className="about-text149">
              <span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
            </span>
          </Fragment>
        }
        member8={
          <Fragment>
            <span className="about-text152">Full name</span>
          </Fragment>
        }
        member7={
          <Fragment>
            <span className="about-text153">Full name</span>
          </Fragment>
        }
        member7Content={
          <Fragment>
            <span className="about-text154">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse varius enim in eros elementum tristique.
            </span>
          </Fragment>
        }
        member8Job={
          <Fragment>
            <span className="about-text155">Job title</span>
          </Fragment>
        }
        heading2={
          <Fragment>
            <span className="about-text156">We’re hiring!</span>
          </Fragment>
        }
        member2={
          <Fragment>
            <span className="about-text157">Jane Smith</span>
          </Fragment>
        }
        member6Job={
          <Fragment>
            <span className="about-text158">Job title</span>
          </Fragment>
        }
        member3Content={
          <Fragment>
            <span className="about-text159">
              Alex ensures our financial strategies are sound and sustainable
              for long-term growth.
            </span>
          </Fragment>
        }
        actionContent={
          <Fragment>
            <span className="about-text160">Open positions</span>
          </Fragment>
        }
        member6={
          <Fragment>
            <span className="about-text161">Full name</span>
          </Fragment>
        }
        member5={
          <Fragment>
            <span className="about-text162">Full name</span>
          </Fragment>
        }
        content3={
          <Fragment>
            <span className="about-text163">
              <span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
            </span>
          </Fragment>
        }
        member4Job={
          <Fragment>
            <span className="about-text166">COO</span>
          </Fragment>
        }
        member1Job={
          <Fragment>
            <span className="about-text167">CEO</span>
          </Fragment>
        }
        member1={
          <Fragment>
            <span className="about-text168">John Doe</span>
          </Fragment>
        }
        member4={
          <Fragment>
            <span className="about-text169">Sarah Brown</span>
          </Fragment>
        }
        member7Job={
          <Fragment>
            <span className="about-text170">Job title</span>
          </Fragment>
        }
        member3={
          <Fragment>
            <span className="about-text171">Alex Johnson</span>
          </Fragment>
        }
        member1Content={
          <Fragment>
            <span className="about-text172">
              John is a visionary leader with a passion for innovation and
              customer satisfaction.
            </span>
          </Fragment>
        }
        content1={
          <Fragment>
            <span className="about-text173">Join Our Team Today!</span>
          </Fragment>
        }
        member6Content={
          <Fragment>
            <span className="about-text174">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse varius enim in eros elementum tristique.
            </span>
          </Fragment>
        }
        member5Job={
          <Fragment>
            <span className="about-text175">Job title</span>
          </Fragment>
        }
      ></Team1>
    </div>



    <Footer/>
          
          </div>
  )
}
