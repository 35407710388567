import { onAuthStateChanged, signOut } from 'firebase/auth';
import React, { useState,useEffect  } from 'react'
import { Link, useHistory,} from 'react-router-dom/cjs/react-router-dom';
import { auth, fireStore } from '../firebase';
import { collection, doc, onSnapshot } from 'firebase/firestore';



export default function Navigation(props) {
    const [navPop,setNavPop] = useState(props.navigationPop)
    const [users, setusers] = useState([]);
    const [cart, setcart] = useState([]);
    const [uid, setuid] = useState();


    const [currentIndexes, setCurrentIndexes] = useState({});
    const [isLoading, setisLoading] = useState(true);

    const history = useHistory()


    useEffect(() => {
      const unsubscribe = onAuthStateChanged(auth, (user) => {
        if (user) {
          const uid = user.uid;
          setuid(uid)
          const userDocRef = doc(fireStore, 'TotalUsers', uid);
          const cartCollectionRef = collection(userDocRef, 'Cart');
    
          const unsubscribeUserDoc = onSnapshot(userDocRef, (userSnapshot) => {
            setusers([{ ...userSnapshot.data(), id: userSnapshot.id }]);
          });
    
          const unsubscribeCartCollection = onSnapshot(cartCollectionRef, (cartSnapshot) => {
            const cartItems = cartSnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
            setcart(cartItems);
          });
    
          return () => {
            unsubscribeUserDoc();
            unsubscribeCartCollection();
            unsubscribe();
          };
        } else {
          console.log("logged out");
        }
      });
    }, [handleLogOut]);
    function navPopHandler(){
      if(navPop === true){
        setNavPop(false)
      }
      else{
        setNavPop(true)
      }
    } 
    function handleLogOut(){
        signOut(auth).then(
         history.push('/')
        )
       }
  return (
    users.length !== 0 ? users.map(i=>{
      const {photo,fullName} = i
      return(
        <div className='w-full relative'>
                    {
        navPop === false &&
      <div className="home-navbar navbar-container " style={{backgroundColor:'#002efb'}}>
        <div className="max-width">
          <header data-thq="thq-navbar" className="home-navbar-interactive">
            <div >
    <Link to='/collection' className="home-logo1 ">
      <img
      style={{width:'100%'}}
                      alt="image"
                      src="https://rimmon.s3.us-east-1.amazonaws.com/ephratawi__4_-removebg-preview.png"
                      className="home-image2"
                    />
                    </Link>
            </div>
            <div data-thq="thq-navbar-nav" className="home-desktop-menu">
              <div className="home-links1">
              <Link to='/collection/kids'>
              <span className="home-text104 navbarLink cursor-pointer hover:text-[#13033c]" style={{color:'white'}}>Kids</span>
              </Link>
              <Link to='/collection/women'>
                <span className="home-text103 navbarLink cursor-pointer hover:text-[#13033c] "style={{color:'white'}}>Women</span>
                </Link>
                {/* <Link to='/collection/uniform'>
                <span className="home-text105 navbarLink cursor-pointer hover:text-[#13033c]" style={{color:'white'}}>Uniforms</span>
                </Link> */}
                <Link to='/contact'>
                <span className="home-text106 navbarLink cursor-pointer hover:text-[#13033c]" style={{color:'white'}}>Contact </span>
                </Link>
                <Link to='/orders'>
                <button className="button-secondary button">Orders</button>
                </Link>
                <button onClick={handleLogOut} className="button button-primary hover:text-gray-950" style={{backgroundColor:'#c7ff9bff',color:'black'}}>Sign Out</button>
               
              
                  {
              photo && photo.length != 0 ?
                 <Link to='/profile'>
         <button  type="button" class="flex mx-2 text-sm bg-gray-800 rounded-full md:mr-0 focus:ring-4 focus:ring-gray-300 #" id="user-menu-button" aria-expanded="false" data-dropdown-toggle="dropdown">
                  <span class="sr-only">Open user menu</span>
                  <img class="w-12 h-12 rounded-full" src={photo} alt="user photo"/>
              </button></Link>
              :
              <Link to='/profile'>

              <div className={`z-100 profilePageImage mx-2 flex items-center justify-center w-12 h-12 l bg-white   m-auto rounded-full object-cover `} style={{ position: 'relative' }}>
              <p className="young capitalize text-gray-900" style={{ fontSize: '12px' }}>
                {fullName && fullName[0]}
              </p>
            </div>
            </Link>
            }

<Link
                    className="relative mx-2 nds-btn nds-button--icon-only nav-bag-icon css-17i884h ex41m6f0 btn-primary-light "
                 to={`/collection/${uid}/checkout`}
                    title={`Cart Items: ${cart.length}`}
                    style={{overflow:'visible',color:'white'}}
                  >
                             <div class="-t-6 absolute left-6">
    <p class="par flex h-1.5 w-1.5 items-center justify-center rounded-full bg-white p-2.5 text-xs text-gray-950">{cart.length}</p>
  </div>
                    <div className=" css-rguovb relative" >
           
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        viewBox="0 0 24 24"
                        role="img"
                        width="24px"
                        height="24px"
                        fill="none"
                      >
                        <path
                          stroke="currentColor"
                          strokeWidth="1.5"
                          d="M8.25 8.25V6a2.25 2.25 0 012.25-2.25h3a2.25 2.25 0 110 4.5H3.75v8.25a3.75 3.75 0 003.75 3.75h9a3.75 3.75 0 003.75-3.75V8.25H17.5"
                        />
                      </svg>
                      <p className="css-79q9fn" />
                    </div>
                    <span className="ripple" />
                  </Link>
              </div>
            </div>
            
            <div data-thq="thq-burger-menu" className="home-burger-menu">
     
              {
              photo && photo.length != 0 ?
                 <Link to='/profile'>
         <button  type="button" class="flex mx-2 text-sm bg-gray-800 rounded-full md:mr-0 focus:ring-4 focus:ring-gray-300 #" id="user-menu-button" aria-expanded="false" data-dropdown-toggle="dropdown">
                  <span class="sr-only">Open user menu</span>
                  <img class="w-12 h-12 rounded-full" src={photo} alt="user photo"/>
              </button></Link>
              :
              <Link to='/profile'>

              <div className={`z-100 profilePageImage mx-2 flex items-center justify-center w-12 h-12 l bg-white   m-auto rounded-full object-cover `} style={{ position: 'relative' }}>
              <p className="young capitalize text-gray-900" style={{ fontSize: '12px' }}>
                {fullName && fullName[0]}
              </p>
            </div>
            </Link>
            }
         <Link to='/checkout'>
              <svg
                    aria-hidden="true"
                    focusable="false"
                    viewBox="0 0 24 24"
                    role="img"
                    width="22px"
                    height="22px"
                    fill="none"
                    style={{padding:0,marginRight:10,color:'white'}}
                    className='home-text106 navbarLink cursor-pointer hover:text-[#13033c] '
                  >
                    <path
                      stroke="currentColor"
                      strokeWidth="1.5"
                      d="M8.25 8.25V6a2.25 2.25 0 012.25-2.25h3a2.25 2.25 0 110 4.5H3.75v8.25a3.75 3.75 0 003.75 3.75h9a3.75 3.75 0 003.75-3.75V8.25H17.5"
                    />
                  </svg>
              </Link>

          
            
            <button
              
              className="nds-btn  nav-bag-icon css-17i884h ex41m6f0 btn-primary-light "
              onClick={navPopHandler}
            >
              <div className='flex items-center justify-center'>
                
          <svg class="w-5 h-5 " style={{color:'white'}}  aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12"> <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h14M1 6h14M1 11h7"/> </svg>

              </div>
            </button>
            </div>
             
          </header>
        </div>
      </div>
      }
 {
                navPop === true &&
                <div className="p-6 fixed top-0 left-0 w-full h-full bg-white z-50">
                <div className="home-nav">
                  <div className="home-top">
                   <div className="home-logo2" onClick={navPopHandler}>
                      <img
                        alt="image"
                        src="https://rimmon.s3.us-east-1.amazonaws.com/ephratawi+(5).png"
                  
                        className="home-image2"
                      />
                     
                    </div>
                    <div onClick={navPopHandler} data-thq="thq-close-menu" className="home-close-menu cursor-pointer">
                      <svg viewBox="0 0 1024 1024" className="home-icon12 cursor-pointer">
                        <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                      </svg>
                    </div>
                  </div>
                  <nav className="home-links2">
                  <Link to='/collection/about'>
                    <span className="home-text113 navbarLink cursor-pointer hover:text-[#13033c]">About</span>
                    </Link>
                  <Link to='/collection/kids'>
                    <span className="home-text113 navbarLink cursor-pointer hover:text-[#13033c]">Kids</span>
                    </Link>
                 
                    <Link to='/collection/women'>
                    <span className="home-text113 navbarLink cursor-pointer hover:text-[#13033c]">Women</span>
                    </Link>
              
                    
                    <Link to='/collection'>
                    <span className="home-text112 navbarLink cursor-pointer hover:text-[#13033c]">Collection</span>
                    </Link>
                    <Link to='/contact'>
                    <span className="home-text112 navbarLink cursor-pointer hover:text-[#13033c]">Contact </span>
                    </Link>
                  </nav>
                  <div className="home-buttons">
                    <Link to='/orders'>
                    <button className="button-secondary button button-secondary-mobile ">Orders</button>
                    </Link>
                    <button onClick={handleLogOut} className="button button-primary hover:text-gray-950 button-secondary-mobile">Sign Out</button>

                   
                  </div>
                </div>
                <div className='flex mt-10'>
<a href="https://www.facebook.com/profile.php?id=61568595905544" target='_blank' class="home-icon16 ">
                  <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 8 19">
                        <path fill-rule="evenodd" d="M6.135 3H8V0H6.135a4.147 4.147 0 0 0-4.142 4.142V6H0v3h2v9.938h3V9h2.021l.592-3H5V3.591A.6.6 0 0 1 5.592 3h.543Z" clip-rule="evenodd"/>
                    </svg>
                  <span class="sr-only">Facebook page</span>
              </a>
              <a href="https://www.tiktok.com/@ephratawi.designs?_t=8rMVRjKSDSy&_r=1" target='_blank' class="home-icon16">
               
               <svg xmlns="http://www.w3.org/2000/svg"  fill="currentColor" class="w-4 h-4 bi bi-tiktok" viewBox="0 0 16 16">
<path d="M9 0h1.98c.144.715.54 1.617 1.235 2.512C12.895 3.389 13.797 4 15 4v2c-1.753 0-3.07-.814-4-1.829V11a5 5 0 1 1-5-5v2a3 3 0 1 0 3 3z"/>
</svg>
             <span class="sr-only">Tiktok</span>
         </a>
         <a href="https://t.me/+aS7rvKs_sDg2YzA0"target='_blank' class="home-icon18  ">
                
                <svg xmlns="http://www.w3.org/2000/svg"  fill="currentColor" class="w-4 h-4 bi bi-telegram" viewBox="0 0 16 16">
<path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.287 5.906q-1.168.486-4.666 2.01-.567.225-.595.442c-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294q.39.01.868-.32 3.269-2.206 3.374-2.23c.05-.012.12-.026.166.016s.042.12.037.141c-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8 8 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629q.14.092.27.187c.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.4 1.4 0 0 0-.013-.315.34.34 0 0 0-.114-.217.53.53 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09"/>
</svg>
                <span class="sr-only">Telegram</span>
            </a>
</div>
              </div>
              }
        </div>
      )
    })
    :
    props.component === 'Collection' || props.component === 'Profile' ||props.component === 'Cloth' || props.component === 'Register'||  props.component === 'Login'||  props.component === 'Contact'||  props.component === 'About'  ?
    <div className='w-full'>
    {
navPop === false &&
<div className="home-navbar navbar-container " style={{backgroundColor:'#002efb'}}>
<div className="max-width">
  <header data-thq="thq-navbar" className="home-navbar-interactive">
    <div >
<Link to='/' className="home-logo1 ">
<img
style={{width:'100%'}}
              alt="image"
              src="https://rimmon.s3.us-east-1.amazonaws.com/ephratawi__4_-removebg-preview.png"
              className="home-image2"
            />
            </Link>
    </div>
    <div data-thq="thq-navbar-nav" className="home-desktop-menu">
      <div className="home-links1">
      <Link to='/about'>
      <span className="home-text104 navbarLink cursor-pointer hover:text-[#13033c]" style={{color:'white'}}>About Us</span>
      </Link>
      <Link to='/collection/kids'>
      <span className="home-text104 navbarLink cursor-pointer hover:text-[#13033c]" style={{color:'white'}}>Kids</span>
      </Link>
      <Link to='/collection/women'>
        <span className="home-text103 navbarLink cursor-pointer hover:text-[#13033c]" style={{color:'white'}}>Women</span>
        </Link>
 
        <Link to='/contact'>
        <span className="home-text106 navbarLink cursor-pointer hover:text-[#13033c]" style={{color:'white'}}>Contact </span>
        </Link>
        <Link to='/login'>
        <button className="button-secondary button">Log in</button>
        </Link>
        <Link to='/register'>
        <button className="button button-primary hover:text-gray-950" style={{backgroundColor:'#c7ff9bff',color:'black'}}>Get started</button>
        </Link>
{/*                
        <Link to='/checkout'>
        <svg
            aria-hidden="true"
            focusable="false"
            viewBox="0 0 24 24"
            role="img"
            width="26px"
            height="26px"
            fill="none"
            style={{padding:0,margin:10}}
            className='home-text106 navbarLink cursor-pointer hover:text-[#13033c] ml-2'
          >
            <path
              stroke="currentColor"
              strokeWidth="1.5"
              d="M8.25 8.25V6a2.25 2.25 0 012.25-2.25h3a2.25 2.25 0 110 4.5H3.75v8.25a3.75 3.75 0 003.75 3.75h9a3.75 3.75 0 003.75-3.75V8.25H17.5"
            />
          </svg>
          </Link> */}



      </div>
    </div>
    
    <div data-thq="thq-burger-menu" className="home-burger-menu"  style={{color:'white'}}>
 <Link to='/register'>
 <button style={{borderColor:'white',color:'white'}} className="button-secondary  button-secondary-mobile button bg-transparent">
        Register
      </button></Link>
 

      
      <button
                  
                  className="nds-btn  nav-bag-icon css-17i884h ex41m6f0 btn-primary-light "
                  onClick={navPopHandler}
                >
                  <div className='flex items-center justify-center'>
                    
              <svg class="w-5 h-5 "  aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12"> <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h14M1 6h14M1 11h7"/> </svg>

                  </div>
                </button>
    </div>
     
  </header>
</div>
</div>
}
{
navPop === true &&
<div data-thq="thq-mobile-menu" className="p-6 fixed top-0 left-0 w-full h-full bg-white z-50">
<div className="home-nav">
  <div className="home-top">
   <div className="home-logo2" onClick={navPopHandler}>
      <img
        alt="image"
        src="https://rimmon.s3.us-east-1.amazonaws.com/ephratawi+(5).png"

        className="home-image2"
      />
     
    </div>
    <div onClick={navPopHandler} data-thq="thq-close-menu" className="home-close-menu">
      <svg viewBox="0 0 1024 1024" className="home-icon12 cursor-pointer">
        <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
      </svg>
    </div>
  </div>
  <nav className="home-links2">
  <Link to='/collection/about'>
    <span className="home-text110 navbarLink cursor-pointer hover:text-[#13033c]">About us</span>
    </Link>
    <Link to='/collection/kids'>
    <span className="home-text110 navbarLink cursor-pointer hover:text-[#13033c]">Kids</span>
    </Link>
    <Link to='/collection/women'>
    <span className="home-text113 navbarLink cursor-pointer hover:text-[#13033c]">Women</span>
    </Link>
   
    
    <Link to='/collection'>
    <span className="home-text112 navbarLink cursor-pointer hover:text-[#13033c]">Collection</span>
    </Link>
  </nav>
  <div className="home-buttons">
    <Link to='/login'>
    <button className="button-secondary button button-secondary-mobile ">Log In</button>
    </Link>
    <Link to='/contact'>
    <button className="button button-primary hover:text-gray-950 button-secondary-mobile">Contact </button>
    </Link>

   
  </div>
</div>
<div className='flex mt-10'>
<a href="https://www.facebook.com/profile.php?id=61568595905544" target='_blank' class="home-icon16 ">
                  <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 8 19">
                        <path fill-rule="evenodd" d="M6.135 3H8V0H6.135a4.147 4.147 0 0 0-4.142 4.142V6H0v3h2v9.938h3V9h2.021l.592-3H5V3.591A.6.6 0 0 1 5.592 3h.543Z" clip-rule="evenodd"/>
                    </svg>
                  <span class="sr-only">Facebook page</span>
              </a>
              <a href="https://www.tiktok.com/@ephratawi.designs?_t=8rMVRjKSDSy&_r=1" target='_blank' class="home-icon16">
               
               <svg xmlns="http://www.w3.org/2000/svg"  fill="currentColor" class="w-4 h-4 bi bi-tiktok" viewBox="0 0 16 16">
<path d="M9 0h1.98c.144.715.54 1.617 1.235 2.512C12.895 3.389 13.797 4 15 4v2c-1.753 0-3.07-.814-4-1.829V11a5 5 0 1 1-5-5v2a3 3 0 1 0 3 3z"/>
</svg>
             <span class="sr-only">Tiktok</span>
         </a>
         <a href="https://t.me/+aS7rvKs_sDg2YzA0"target='_blank' class="home-icon18  ">
                
                <svg xmlns="http://www.w3.org/2000/svg"  fill="currentColor" class="w-4 h-4 bi bi-telegram" viewBox="0 0 16 16">
<path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.287 5.906q-1.168.486-4.666 2.01-.567.225-.595.442c-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294q.39.01.868-.32 3.269-2.206 3.374-2.23c.05-.012.12-.026.166.016s.042.12.037.141c-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8 8 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629q.14.092.27.187c.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.4 1.4 0 0 0-.013-.315.34.34 0 0 0-.114-.217.53.53 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09"/>
</svg>
                <span class="sr-only">Telegram</span>
            </a>
</div>
</div>
}
</div>
:
<div className='w-full'>
{
navPop === false &&
<div className="home-navbar navbar-container " style={{backgroundColor:'#002efb'}}>
<div className="max-width">
<header data-thq="thq-navbar" className="home-navbar-interactive">
<div >
<Link to='/' className="home-logo1 ">
<img
style={{width:'100%'}}
          alt="image"
          src="https://rimmon.s3.us-east-1.amazonaws.com/ephratawi__4_-removebg-preview.png"
          className="home-image2"
        />
        </Link>
</div>
<div data-thq="thq-navbar-nav" className="home-desktop-menu">
  <div className="home-links1">
  <Link to='/about'>
  <span className="home-text104 navbarLink cursor-pointer hover:text-[#13033c]" style={{color:'white'}}>About Us</span>
  </Link>
  <Link to='/collection/kids'>
  <span className="home-text104 navbarLink cursor-pointer hover:text-[#13033c]" style={{color:'white'}}>Kids</span>
  </Link>
  <Link to='/collection/women'>
    <span className="home-text103 navbarLink cursor-pointer hover:text-[#13033c]" style={{color:'white'}}>Women</span>
    </Link>

    <Link to='/contact'>
    <span className="home-text106 navbarLink cursor-pointer hover:text-[#13033c]" style={{color:'white'}}>Contact </span>
    </Link>
    <Link to='/login'>
    <button className="button-secondary button">Log in</button>
    </Link>
    <Link to='/register'>
    <button className="button button-primary hover:text-gray-950" style={{backgroundColor:'#c7ff9bff',color:'black'}}>Get started</button>
    </Link>
{/*                
    <Link to='/checkout'>
    <svg
        aria-hidden="true"
        focusable="false"
        viewBox="0 0 24 24"
        role="img"
        width="26px"
        height="26px"
        fill="none"
        style={{padding:0,margin:10}}
        className='home-text106 navbarLink cursor-pointer hover:text-[#13033c] ml-2'
      >
        <path
          stroke="currentColor"
          strokeWidth="1.5"
          d="M8.25 8.25V6a2.25 2.25 0 012.25-2.25h3a2.25 2.25 0 110 4.5H3.75v8.25a3.75 3.75 0 003.75 3.75h9a3.75 3.75 0 003.75-3.75V8.25H17.5"
        />
      </svg>
      </Link> */}



  </div>
</div>

<div data-thq="thq-burger-menu" className="home-burger-menu"  style={{color:'white'}}>
<Link to='/register'>
<button style={{borderColor:'white',color:'white'}} className="button-secondary  button-secondary-mobile button bg-transparent">
    Register
  </button></Link>


  
  <button
              
              className="nds-btn  nav-bag-icon css-17i884h ex41m6f0 btn-primary-light "
              onClick={navPopHandler}
            >
              <div className='flex items-center justify-center'>
                
          <svg class="w-5 h-5 "  aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12"> <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h14M1 6h14M1 11h7"/> </svg>

              </div>
            </button>
</div>
 
</header>
</div>
</div>
}
{
navPop === true &&
<div data-thq="thq-mobile-menu" className="p-6 fixed top-0 left-0 w-full h-full bg-white z-50">
<div className="home-nav">
<div className="home-top">
<div className="home-logo2" onClick={navPopHandler}>
  <img
    alt="image"
    src="https://rimmon.s3.us-east-1.amazonaws.com/ephratawi+(5).png"

    className="home-image2"
  />
 
</div>
<div onClick={navPopHandler} data-thq="thq-close-menu" className="home-close-menu">
  <svg viewBox="0 0 1024 1024" className="home-icon12 cursor-pointer">
    <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
  </svg>
</div>
</div>
<nav className="home-links2">
<Link to='/collection/about'>
    <span className="home-text110 navbarLink cursor-pointer hover:text-[#13033c]">About us</span>
    </Link>
<Link to='/collection/kids'>
<span className="home-text110 navbarLink cursor-pointer hover:text-[#13033c]">Kids</span>
</Link>
<Link to='/collection/women'>
<span className="home-text113 navbarLink cursor-pointer hover:text-[#13033c]">Women</span>
</Link>


<Link to='/collection'>
<span className="home-text112 navbarLink cursor-pointer hover:text-[#13033c]">Collection</span>
</Link>

</nav>
<div className="home-buttons">
<Link to='/login'>
<button className="button-secondary button button-secondary-mobile ">Login</button>
</Link>
<Link to='/contact'>
<button className="button button-primary hover:text-gray-950 button-secondary-mobile">Contact </button>
</Link>


</div>
</div>
<div className='flex mt-10'>
<a href="https://www.facebook.com/profile.php?id=61568595905544" target='_blank' class="home-icon16 ">
                  <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 8 19">
                        <path fill-rule="evenodd" d="M6.135 3H8V0H6.135a4.147 4.147 0 0 0-4.142 4.142V6H0v3h2v9.938h3V9h2.021l.592-3H5V3.591A.6.6 0 0 1 5.592 3h.543Z" clip-rule="evenodd"/>
                    </svg>
                  <span class="sr-only">Facebook page</span>
              </a>
              <a href="https://www.tiktok.com/@ephratawi.designs?_t=8rMVRjKSDSy&_r=1" target='_blank' class="home-icon16">
               
               <svg xmlns="http://www.w3.org/2000/svg"  fill="currentColor" class="w-4 h-4 bi bi-tiktok" viewBox="0 0 16 16">
<path d="M9 0h1.98c.144.715.54 1.617 1.235 2.512C12.895 3.389 13.797 4 15 4v2c-1.753 0-3.07-.814-4-1.829V11a5 5 0 1 1-5-5v2a3 3 0 1 0 3 3z"/>
</svg>
             <span class="sr-only">Tiktok</span>
         </a>
         <a href="https://t.me/+aS7rvKs_sDg2YzA0"target='_blank' class="home-icon18  ">
                
                <svg xmlns="http://www.w3.org/2000/svg"  fill="currentColor" class="w-4 h-4 bi bi-telegram" viewBox="0 0 16 16">
<path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.287 5.906q-1.168.486-4.666 2.01-.567.225-.595.442c-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294q.39.01.868-.32 3.269-2.206 3.374-2.23c.05-.012.12-.026.166.016s.042.12.037.141c-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8 8 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629q.14.092.27.187c.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.4 1.4 0 0 0-.013-.315.34.34 0 0 0-.114-.217.53.53 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09"/>
</svg>
                <span class="sr-only">Telegram</span>
            </a>
</div>
</div>
}
</div>
  
  )
}
