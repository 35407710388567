import React, { useEffect, useState } from 'react'
// import Nav from './nav'
import { onAuthStateChanged } from 'firebase/auth';
import { auth, fireStore, storage } from '../firebase';
import { collection, updateDoc, doc, getDoc, onSnapshot,setDoc,addDoc, serverTimestamp, getDocs, Timestamp } from 'firebase/firestore';
import { getStorage,getDownloadURL,uploadBytesResumable,ref } from 'firebase/storage';
import Navigation from './Navigation';

export default function Profile() {
  const [user,setuser] = useState([])
  const [uid,setuid] = useState('')
  const [fullNameError,setfullNameError] = useState('')
  const [fullName,setfullName] = useState('')
  const [photoHover,setphotoHover] = useState(false)
  const [uploadMessage,setuploadMessage] = useState('')
  const [profile,setprofile] = useState([])
  const [progress,setprogress] = useState()
  const [loading,setloading] = useState(false)
  const [deletePop,setdeletePop] = useState(false)

  const [navPop,setNavPop] = useState(false)








  const [fullNameEditor,setfullNameEditor] = useState(false)

function editorOpen(){
  setfullNameEditor(true)
}

function fullNameSet(e){
  setfullName(e.target.value)
  if(e.target.value.length === 0){
    setfullNameError('error')
  }
  else{
    setfullNameError('')
  }

}
function changeFullName(){
  const userDoc = doc(fireStore,'TotalUsers',uid)
  setDoc(userDoc,{
    fullName:fullName
  },{merge:true}).then(setfullNameEditor(false))

}
function handleImgSelect(event) {
  const selectedFiles = event.target.files[0];

  // Check file type
  const allowedTypes = ['image/jpeg', 'image/png', 'image/gif'];
  if (!allowedTypes.includes(selectedFiles.type)) {
    setuploadMessage(`Only JPEG, PNG, and GIF files are allowed.`);
    return false;
  }

  // Check file size
  const maxSize = 10 * 1024 * 1024; // 10MB
  if (selectedFiles.size > maxSize) {
    setuploadMessage(`File ${selectedFiles.name} exceeds the size limit of 10MB.`);
    return false;
  }

  // Clear the upload message and update the profile state
  setuploadMessage('');
  setprofile(selectedFiles);

  // Clear the file input value to allow selecting the same file again
  event.target.value = '';
}

function profilePhotoHoverEnterHandler(){
  setphotoHover(true)
}
function profilePhotoHoverLeaveHandler(){
  setphotoHover(false)
}
function editorClose(){
  setfullNameEditor(false)
}
function getDateFromTimestamp(timestamp) {
  if (timestamp) {
    if (timestamp instanceof Timestamp) {
      return timestamp.toDate().toISOString().slice(0, 19).replace('T', ' ');
    } else {
      return null;
    }
  } else {
    return null;
  }
}
function uploadProfilePhoto (){
  const reff = ref(storage, `profile/${profile.name}`);
  const uploadImage = uploadBytesResumable(reff, profile);
  uploadImage.on(
    'state_changed',
    (snapshot) => {
      const progress = Math.round(
        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      );
      setprogress(progress);
      setloading(true)

    },
    (error) => {
      console.log(error);
    },
    async () => {
      const downloadURL = await getDownloadURL(uploadImage.snapshot.ref);
      const userDoc = doc(fireStore, 'TotalUsers', uid);

      const data = {
 
        photo: downloadURL,
        
      };

      await updateDoc(userDoc, data);
  

      setprogress(0);
      setloading(false)
      setprofile([])
    }
  );

}
  function openImageDeleteDesktop(){
    setdeletePop(true)
  }

  async function removeProfilePicture(){
    const userDoc = doc(fireStore,'TotalUsers',uid)
    await setDoc(userDoc,{photo:''},{merge:true}).then(
      setdeletePop(false)

    )

  }

  function closeProfileDeleteDesktop (){
    setdeletePop(false)

  
  }

  useEffect(() => {

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
    
        const uid = user.uid;
setuid(uid)
        onSnapshot(doc(fireStore,'TotalUsers',uid),snapshot=>{
          let temp = []
          temp.push({...snapshot.data()})
          setuser(temp)
        })
  
  
            
  
              
          
  
          
      
  
             
  
             
             
  
                
      
  
        
  
       
        
        // ...
      } else {
        console.log("logged out")
        
      }
    });
  
    
      
  
    return () => {
      unsubscribe();
    };
  }, []);
  return (
    user.map(i=>{
      const {fullName,email,photo,time} = i
      return(
        <div>
            <Navigation navigationPop={navPop} component={'Profile'}/>
        <div id='desktop'>
            {/* <Nav/> */}
            {
  deletePop == true && 
  <div id='deleteProfile' className=' flex items-center justify-center'>
         
       
  <div className=' m-auto  rounded  my-5 flex items-center justify-center' id='subscriptionDesktop' style={{width:"50%",height:"50%"}}>
<div class="max-w-sm p-2 mx-auto bg-white border-[1px] border-gray-200 " 

>
<div class="relative p-6">
<a onClick={closeProfileDeleteDesktop}   class="absolute top-1.5 right-1.5">
<svg  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 cursor-pointer fill-current text-slate-500 hover:text-slate-900">
  <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
</svg>
  
</a>
<h1 class="text-3xl  header" style={{fontSize:"20px"}}>Remove Profile Picture </h1>
<p class="text-sm text-gray-500 par normal-case" >Would you like to permanently remove your profile Picture? </p>

<div class="flex flex-row mt-6 space-x-2 justify-evenly">
<a onClick={removeProfilePicture}  class="w-full cursor-pointer py-3 text-sm font-medium text-center text-white transition duration-150 ease-linear bg-red-600 border border-red-600 rounded-lg hover:bg-red-500">Delete</a>
<a onClick={closeProfileDeleteDesktop}   class="w-full cursor-pointer py-3 text-sm text-center text-gray-500 transition duration-150 ease-linear bg-white border border-gray-200 rounded-lg hover:bg-gray-100">Cancel</a>
</div>
</div>
</div>
</div>  


</div>
 }
  <section id='ciclp-app'>
  <div class="w-full lg:w-4/12 px-4 mx-auto">
    {
      loading === true ?
      <div class="relative flex flex-col min-w-0 break-words py-10 border bg-white w-full mb-6 shadow-md rounded-lg mt-16">
    
 

      <div className="flex w-full flex-col items-center justify-center w-full text-surface dark:text-white" >
      <div
        className=" h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] dark:text-white"
        role="status"></div>
          <div className=" bg-gray-200 rounded-full dark:bg-gray-700 mt-5">
        <div className=" text-xs font-medium text-gray-800 text-center p-0.5 leading-none rounded-full" style={{width:progress,background:'#D9FE00'}}> {progress > 20 && progress + '%'} </div>
      </div>
      </div>
    
      
    
    </div>
      :
      <div class="relative flex flex-col min-w-0 break-words py-10 border bg-white w-full mb-6 shadow-md rounded-lg mt-16">
      <div class="px-6">
        
<div className={`${uploadMessage.length !== 0 ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
{uploadMessage}
</div>
</div>
</div>
{
  profile.length !=0 &&
  <button
    className="par px-5 py-2 mt-2 text-white rounded-sm par bg-gray-800 hover:bg-gray-700 focus:shadow-outline focus:outline-none"
   onClick={uploadProfilePhoto}
  >
    Upload Profile
  </button>
}
        <div class="flex flex-wrap justify-center">
        {
          photo && photo.length != 0 ?
          <div className="relative" onMouseEnter={profilePhotoHoverEnterHandler} onMouseLeave={profilePhotoHoverLeaveHandler}>
          {photoHover && (
         
            <div onClick={openImageDeleteDesktop} className="flex cursor-pointer absolute items-center justify-center  bg-gray-900 rounded-full w-20 h-20 m-auto rounded-full object-cover" style={{ opacity: '0.85', position: 'absolute', zIndex: 90 }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-trash-fill text-white" viewBox="0 0 16 16">
<path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5M8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5m3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0"/>
</svg>
            </div>
          )}
         
         <img src={photo} className="w-20 h-20 rounded-full mb-4" style={{ objectFit: 'cover' }} />                 </div>
       
        :
        <div className="relative" onMouseEnter={profilePhotoHoverEnterHandler} onMouseLeave={profilePhotoHoverLeaveHandler}>
        {photoHover && (
          <div>
            <div className="relative mx-auto " style={{width:'80%'}}>
  <input
    accept="image/jpeg, image/png, image/gif"
    type="file"
    id="hidden-input-img"
    className="hidden"
    onChange={handleImgSelect}
  />




          </div>
          <div    onClick={() => {
      document.getElementById('hidden-input-img').click();
    }} className="flex cursor-pointer absolute items-center justify-center  bg-gray-900 rounded-full w-20 h-20 m-auto rounded-full object-cover" style={{ opacity: '0.85', position: 'absolute', zIndex: 90 }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="text-white bi bi-camera-fill" viewBox="0 0 16 16">
              <path d="M10.5 8.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0" />
              <path d="M2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4zm.5 2a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1m9 2.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0" />
            </svg>
          </div>
</div>

        )}
        <div className={`z-100 profilePageImage flex items-center justify-center  bg-blue-600 rounded-full w-20 h-20 m-auto rounded-full object-cover ${photoHover ? 'profilePageImagehover' : ''}`} style={{ position: 'relative' }}>
          <p className="young capitalize text-white" style={{ fontSize: '30px' }}>
            {fullName && fullName[0]}
          </p>
        </div>
      </div>
        }
     
        </div>
        <div class="text-center mt-8 ">
          {
            fullNameEditor === false ?
            <div className='relative'>
            <h3 class="text-xl font-semibold leading-normal  text-blueGray-700 header">
              {fullName}
            </h3>
     
            <svg onClick={editorOpen} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pen absolute right-0 top-0 cursor-pointer hover:text-green-800" viewBox="0 0 16 16">
    <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001m-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708z"/>
  </svg>
            </div>
            :
            <div  className='flex items-center mt-12 mb-5'>
    <div className=" relative mx-auto  "style={{width:'100%'}} >


<p style={{fontSize:'13px'}} className="par bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute">Full Name</p>
<input style={{fontSize:'12px',height:'60px'}} onChange={(e=>fullNameSet(e))}   required  autoComplete="off" placeholder="Abebe Kebede" type="text" className="border  focus:outline-none
focus:border-black w-full pt-5 pr-4 par pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
border-gray-300 rounded-md" />


<div className={`${fullNameError === "error" ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
Full Name Can't be empty
</div>
</div>
</div>
</div>
<div className='ml-3'>
<svg xmlns="http://www.w3.org/2000/svg" onClick={editorClose} width="16" height="16" fill="currentColor" class="hover:text-red-800 cursor-pointer ml-3 bi bi-x-lg" viewBox="0 0 16 16">
  <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
</svg>
<p onClick={changeFullName} className="par cursor-pointer mt-2 hover:text-green-600">
  Change
</p>
</div>
            </div>

        
                    
          }
         
      
          <div class="text-sm leading-normal mt-0 mb-2 text-blueGray-400 font-bold ">
            {email}
          </div>
          <div class="mb-2 text-blueGray-600 mt-10 par">
            <i class="fas fa-briefcase mr-2 text-lg text-blueGray-400"></i>
            Joined - {getDateFromTimestamp(time)}
          </div>
       
        </div>
    
      </div>
    </div>
    }
    
  </div>

  </section>
        </div>
        <div id='tablet'>
            {/* <Nav/> */}
            {
  deletePop == true && 
  <div id='deleteProfile' className=' flex items-center justify-center'>
         
       
  <div className=' m-auto  rounded  my-5 flex items-center justify-center' id='subscriptionDesktop' style={{width:"50%",height:"50%"}}>
<div class="max-w-sm p-2 mx-auto bg-white border-[1px] border-gray-200 " 

>
<div class="relative p-6">
<a onClick={closeProfileDeleteDesktop}   class="absolute top-1.5 right-1.5">
<svg  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 cursor-pointer fill-current text-slate-500 hover:text-slate-900">
  <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
</svg>
  
</a>
<h1 class="text-3xl  header" style={{fontSize:"20px"}}>Remove Profile Picture </h1>
<p class="text-sm text-gray-500 par normal-case" >Would you like to permanently remove your profile Picture? </p>

<div class="flex flex-row mt-6 space-x-2 justify-evenly">
<a onClick={removeProfilePicture}  class="w-full cursor-pointer py-3 text-sm font-medium text-center text-white transition duration-150 ease-linear bg-red-600 border border-red-600 rounded-lg hover:bg-red-500">Delete</a>
<a onClick={closeProfileDeleteDesktop}   class="w-full cursor-pointer py-3 text-sm text-center text-gray-500 transition duration-150 ease-linear bg-white border border-gray-200 rounded-lg hover:bg-gray-100">Cancel</a>
</div>
</div>
</div>
</div>  


</div>
 }
  <section class="pt-16 ">
  <div class="w-full lg:w-4/12 px-4 mx-auto">
    {
      loading === true ?
      <div class="relative flex flex-col min-w-0 break-words py-10 border bg-white w-full mb-6 shadow-md rounded-lg mt-16">
    
 

      <div className="flex w-full flex-col items-center justify-center w-full text-surface dark:text-white" >
      <div
        className=" h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] dark:text-white"
        role="status"></div>
          <div className=" bg-gray-200 rounded-full dark:bg-gray-700 mt-5">
        <div className=" text-xs font-medium text-gray-800 text-center p-0.5 leading-none rounded-full" style={{width:progress,background:'#D9FE00'}}> {progress > 20 && progress + '%'} </div>
      </div>
      </div>
    
      
    
    </div>
      :
      <div class="relative flex flex-col min-w-0 break-words py-10 border bg-white w-full mb-6 shadow-md rounded-lg mt-16">
      <div class="px-6">
        
<div className={`${uploadMessage.length !== 0 ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
{uploadMessage}
</div>
</div>
</div>
{
  profile.length !=0 &&
  <button
    className="par px-5 py-2 mt-2 text-white rounded-sm par bg-gray-800 hover:bg-gray-700 focus:shadow-outline focus:outline-none"
   onClick={uploadProfilePhoto}
  >
    Upload Profile
  </button>
}
        <div class="flex flex-wrap justify-center">
        {
          photo && photo.length != 0 ?
          <div className="relative" onMouseEnter={profilePhotoHoverEnterHandler} onMouseLeave={profilePhotoHoverLeaveHandler}>
          {photoHover && (
         
            <div onClick={openImageDeleteDesktop} className="flex cursor-pointer absolute items-center justify-center w-32 h-32 bg-gray-900 rounded-full w-12 h-12 m-auto rounded-full object-cover" style={{ opacity: '0.85', position: 'absolute', zIndex: 90 }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-trash-fill text-white" viewBox="0 0 16 16">
<path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5M8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5m3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0"/>
</svg>
            </div>
          )}
         
         <img src={photo} className="w-32 h-32 rounded-full mb-4" style={{ objectFit: 'cover' }} />                 </div>
       
        :
        <div className="relative" onMouseEnter={profilePhotoHoverEnterHandler} onMouseLeave={profilePhotoHoverLeaveHandler}>
        {photoHover && (
          <div>
            <div className="relative mx-auto " style={{width:'80%'}}>
  <input
    accept="image/jpeg, image/png, image/gif"
    type="file"
    id="hidden-input-img"
    className="hidden"
    onChange={handleImgSelect}
  />




          </div>
          <div    onClick={() => {
      document.getElementById('hidden-input-img').click();
    }} className="flex cursor-pointer absolute items-center justify-center w-32 h-32 bg-gray-900 rounded-full w-12 h-12 m-auto rounded-full object-cover" style={{ opacity: '0.85', position: 'absolute', zIndex: 90 }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="text-white bi bi-camera-fill" viewBox="0 0 16 16">
              <path d="M10.5 8.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0" />
              <path d="M2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4zm.5 2a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1m9 2.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0" />
            </svg>
          </div>
</div>

        )}
        <div className={`z-100 profilePageImage flex items-center justify-center w-32 h-32 bg-blue-600 rounded-full w-12 h-12 m-auto rounded-full object-cover ${photoHover ? 'profilePageImagehover' : ''}`} style={{ position: 'relative' }}>
          <p className="young capitalize text-white" style={{ fontSize: '30px' }}>
            {fullName && fullName[0]}
          </p>
        </div>
      </div>
        }
     
        </div>
        <div class="text-center mt-8 ">
          {
            fullNameEditor === false ?
            <div className='relative'>
            <h3 class="text-xl font-semibold leading-normal  text-blueGray-700 header">
              {fullName}
            </h3>
     
            <svg onClick={editorOpen} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pen absolute right-0 top-0 cursor-pointer hover:text-green-800" viewBox="0 0 16 16">
    <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001m-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708z"/>
  </svg>
            </div>
            :
            <div  className='flex items-center mt-12 mb-5'>
    <div className=" relative mx-auto  "style={{width:'100%'}} >


<p style={{fontSize:'13px'}} className="par bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute">Full Name</p>
<input style={{fontSize:'12px',height:'60px'}} onChange={(e=>fullNameSet(e))}   required  autoComplete="off" placeholder="Abebe Kebede" type="text" className="border  focus:outline-none
focus:border-black w-full pt-5 pr-4 par pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
border-gray-300 rounded-md" />


<div className={`${fullNameError === "error" ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
Full Name Can't be empty
</div>
</div>
</div>
</div>
<div className='ml-3'>
<svg xmlns="http://www.w3.org/2000/svg" onClick={editorClose} width="16" height="16" fill="currentColor" class="hover:text-red-800 cursor-pointer ml-3 bi bi-x-lg" viewBox="0 0 16 16">
  <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
</svg>
<p onClick={changeFullName} className="par cursor-pointer mt-2 hover:text-green-600">
  Change
</p>
</div>
            </div>

        
                    
          }
         
      
          <div class="text-sm leading-normal mt-0 mb-2 text-blueGray-400 font-bold ">
            {email}
          </div>
          <div class="mb-2 text-blueGray-600 mt-10 par">
            <i class="fas fa-briefcase mr-2 text-lg text-blueGray-400"></i>
            Joined - {getDateFromTimestamp(time)}
          </div>
       
        </div>
    
      </div>
    </div>
    }
    
  </div>

  </section>
        </div>
        <div id='mobile'>
            {/* <Nav/> */}
            {
  deletePop == true && 
  <div id='deleteProfile' className=' flex items-center justify-center'>
         
       
  <div className=' m-auto  rounded  my-5 flex items-center justify-center' id='subscriptionDesktop' style={{width:"80%",height:"50%"}}>
<div class="max-w-sm p-2 mx-auto bg-white border-[1px] border-gray-200 " 

>
<div class="relative p-6">
<a onClick={closeProfileDeleteDesktop}   class="absolute top-1.5 right-1.5">
<svg  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 cursor-pointer fill-current text-slate-500 hover:text-slate-900">
  <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
</svg>
  
</a>
<h1 class="text-3xl  header" style={{fontSize:"20px"}}>Remove Profile Picture </h1>
<p class="text-sm text-gray-500 par normal-case" >Would you like to permanently remove your profile Picture? </p>

<div class="flex flex-row mt-6 space-x-2 justify-evenly">
<a onClick={removeProfilePicture}  class="w-full cursor-pointer py-3 text-sm font-medium text-center text-white transition duration-150 ease-linear bg-red-600 border border-red-600 rounded-lg hover:bg-red-500">Delete</a>
<a onClick={closeProfileDeleteDesktop}   class="w-full cursor-pointer py-3 text-sm text-center text-gray-500 transition duration-150 ease-linear bg-white border border-gray-200 rounded-lg hover:bg-gray-100">Cancel</a>
</div>
</div>
</div>
</div>  


</div>
 }
  <section class="pt-16 ">
  <div class="w-full lg:w-4/12 px-4 mx-auto">
    {
      loading === true ?
      <div class="relative flex flex-col min-w-0 break-words py-10 border bg-white w-full mb-6 shadow-md rounded-lg mt-16">
    
 

      <div className="flex w-full flex-col items-center justify-center w-full text-surface dark:text-white" >
      <div
        className=" h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] dark:text-white"
        role="status"></div>
          <div className=" bg-gray-200 rounded-full dark:bg-gray-700 mt-5">
        <div className=" text-xs font-medium text-gray-800 text-center p-0.5 leading-none rounded-full" style={{width:progress,background:'#D9FE00'}}> {progress > 20 && progress + '%'} </div>
      </div>
      </div>
    
      
    
    </div>
      :
      <div class="relative flex flex-col min-w-0 break-words py-10 border bg-white w-full mb-6 shadow-md rounded-lg mt-16">
      <div class="px-6">
        
<div className={`${uploadMessage.length !== 0 ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
{uploadMessage}
</div>
</div>
</div>
{
  profile.length !=0 &&
  <button
    className="par px-5 py-2 mt-2 text-white rounded-sm par bg-gray-800 hover:bg-gray-700 focus:shadow-outline focus:outline-none"
   onClick={uploadProfilePhoto}
  >
    Upload Profile
  </button>
}
        <div class="flex flex-wrap justify-center">
        {
          photo && photo.length != 0 ?
          <div className="relative" onMouseEnter={profilePhotoHoverEnterHandler} onMouseLeave={profilePhotoHoverLeaveHandler}>
          {photoHover && (
         
            <div onClick={openImageDeleteDesktop} className="flex cursor-pointer absolute items-center justify-center w-32 h-32 bg-gray-900 rounded-full w-12 h-12 m-auto rounded-full object-cover" style={{ opacity: '0.85', position: 'absolute', zIndex: 90 }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-trash-fill text-white" viewBox="0 0 16 16">
<path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5M8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5m3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0"/>
</svg>
            </div>
          )}
         
         <img src={photo} className="w-32 h-32 rounded-full mb-4" style={{ objectFit: 'cover' }} />                 </div>
       
        :
        <div className="relative" onMouseEnter={profilePhotoHoverEnterHandler} onMouseLeave={profilePhotoHoverLeaveHandler}>
        {photoHover && (
          <div>
            <div className="relative mx-auto " style={{width:'80%'}}>
  <input
    accept="image/jpeg, image/png, image/gif"
    type="file"
    id="hidden-input-img"
    className="hidden"
    onChange={handleImgSelect}
  />




          </div>
          <div    onClick={() => {
      document.getElementById('hidden-input-img').click();
    }} className="flex cursor-pointer absolute items-center justify-center w-32 h-32 bg-gray-900 rounded-full w-12 h-12 m-auto rounded-full object-cover" style={{ opacity: '0.85', position: 'absolute', zIndex: 90 }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="text-white bi bi-camera-fill" viewBox="0 0 16 16">
              <path d="M10.5 8.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0" />
              <path d="M2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4zm.5 2a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1m9 2.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0" />
            </svg>
          </div>
</div>

        )}
        <div className={`z-100 profilePageImage flex items-center justify-center w-32 h-32 bg-blue-600 rounded-full w-12 h-12 m-auto rounded-full object-cover ${photoHover ? 'profilePageImagehover' : ''}`} style={{ position: 'relative' }}>
          <p className="young capitalize text-white" style={{ fontSize: '30px' }}>
            {fullName && fullName[0]}
          </p>
        </div>
      </div>
        }
     
        </div>
        <div class="text-center mt-8 ">
          {
            fullNameEditor === false ?
            <div className='relative'>
            <h3 class="text-xl font-semibold leading-normal  text-blueGray-700 header">
              {fullName}
            </h3>
     
            <svg onClick={editorOpen} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pen absolute right-0 top-0 cursor-pointer hover:text-green-800" viewBox="0 0 16 16">
    <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001m-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708z"/>
  </svg>
            </div>
            :
            <div  className='flex items-center mt-12 mb-5'>
    <div className=" relative mx-auto  "style={{width:'100%'}} >


<p style={{fontSize:'13px'}} className="par bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute">Full Name</p>
<input style={{fontSize:'12px',height:'60px'}} onChange={(e=>fullNameSet(e))}   required  autoComplete="off" placeholder="Abebe Kebede" type="text" className="border  focus:outline-none
focus:border-black w-full pt-5 pr-4 par pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
border-gray-300 rounded-md" />


<div className={`${fullNameError === "error" ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
Full Name Can't be empty
</div>
</div>
</div>
</div>
<div className='ml-3'>
<svg xmlns="http://www.w3.org/2000/svg" onClick={editorClose} width="16" height="16" fill="currentColor" class="hover:text-red-800 cursor-pointer ml-3 bi bi-x-lg" viewBox="0 0 16 16">
  <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
</svg>
<p onClick={changeFullName} className="par cursor-pointer mt-2 hover:text-green-600">
  Change
</p>
</div>
            </div>

        
                    
          }
         
      
          <div class="text-sm leading-normal mt-0 mb-2 text-blueGray-400 font-bold ">
            {email}
          </div>
          <div class="mb-2 text-blueGray-600 mt-10 par">
            <i class="fas fa-briefcase mr-2 text-lg text-blueGray-400"></i>
            Joined - {getDateFromTimestamp(time)}
          </div>
       
        </div>
    
      </div>
    </div>
    }
    
  </div>

  </section>
        </div>
      </div>
      )
    })
  
  )
}
