import React, { Fragment, useState } from 'react'

import { Helmet } from 'react-helmet'

import ContactForm5 from '../components/contact-form5'
import Contact8 from '../components/contact8'
import './contact.css'
import { Link } from 'react-router-dom/cjs/react-router-dom'
import Footer from './Footer'
import Navigation from './Navigation'

const Contact = (props) => {
    const [navPop,setNavPop] = useState(false)


   
  return (
    <div className="contact-container1" style={{background:'#fffef3ff'}}>
      <Helmet>
        <title>Contact - Ephratawi</title>
        <meta property="og:title" content="Contact - Ephratawi" />
      </Helmet>
      <Navigation navigationPop={navPop} component={'Contact'}/>

   {
    <div className='w-full'>
    <ContactForm5
        content1={
          <Fragment>
            <span className="contact-text25">
             
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
            </span>
          </Fragment>
        }
        heading1={
          <Fragment>
            <span className="contact-text28">Contact us</span>
          </Fragment>
        }
        content2={
          <Fragment>
            <span 
                   style={{fontSize:'25px',marginTop:'50px',textAlign:'center',display:'flex',justifyContent:'center'}}       className="nds-text _1MF91zHG css-7vq5hu e1yhcai00 appearance-display2 text-white weight-medium uppercase"
                   >Get in touch with us today!</span>
          </Fragment>
        }
        action={
          <Fragment>
            <span className="contact-text30">Submit</span>
          </Fragment>
        }
      ></ContactForm5>
      <Contact8
        location2Details={
          <Fragment>
            <span className="contact-text31">

            </span>
          </Fragment>
        }
        location2={
          <Fragment>
            <span className="contact-text32">Cluj - Napoca</span>
          </Fragment>
        }
        heading1={
          <Fragment>
            <span className="contact-text33">Contact Us</span>
          </Fragment>
        }
        location1Details={
          <Fragment>
            <span className="contact-text34">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse varius enim in ero.
            </span>
          </Fragment>
        }
        location1={
          <Fragment>
            <span className="contact-text35">Bucharest</span>
          </Fragment>
        }
        content1={
          <Fragment>
            <span className="contact-text36">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse varius enim in ero.
            </span>
          </Fragment>
        }
      ></Contact8>
     <Footer/>
    </div>
   }
    </div>
  )
}

export default Contact
