import React, { useState } from 'react'

import { Helmet } from 'react-helmet'

import './not-found.css'
import Navigation from './Navigation'

const NotFound = (props) => {
  const [navPop,setNavPop] = useState(false)

  return (
    <div className="">
      <Helmet>
        <title>404 - Not Found</title>
      </Helmet>
<Navigation navigationPop={navPop} component='About'/>
<div className="flex flex-col justify-center items-center">

      <h3 className='mt-12'>OOPS! PAGE NOT FOUND</h3>
      <div className="not-found-container2">
        <h1 className="not-found-text2">404</h1>
      </div>
      <div className="not-found-container3">
        <h2 className="not-found-text3">
          WE ARE SORRY, BUT THE PAGE YOU REQUESTED WAS NOT FOUND
        </h2>
      </div>
      </div>
    </div>
  )
}

export default NotFound
